.image {
  width: 100%;
  height: 160px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  @media(min-width:768px) {
    height: 240px;
  }
  @media(min-width:1024px) {
    height: 300px;
  }
  //margin:0 auto;

  //display:block;
  //margin:1rem 0;
}
.Home {
  display:block;
  //> ul {
  //  display:grid;
  //  grid-template-columns: 1fr 1fr;
  //  grid-gap:1rem;
  //  @media(max-width:700px) {
  //    grid-template-columns: 1fr;
  //  }
  //  list-style:none;
  //  padding:0;
  //  margin:0;
  //  li {
  //    list-style:none;
  //    padding:0;
  //    margin:0;
  //    //text-align:left;
  //    > div {
  //      width:100%;
  //      height:300px;
  //      background-size:contain;
  //      background-position: center;
  //      background-repeat: no-repeat;
  //      //margin:0 auto;
  //      //display:block;
  //      //margin:1rem 0;
  //
  //    }
  //    span {
  //      font-size:1.1rem;
  //    }
  //  }
  //}
  //h3 {
  //  span {
  //    color: #c00000;
  //  }
  //}
}