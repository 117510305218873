body {
  //background-image:url('/static/bg-pattern-light.png');
  //background-repeat: no-repeat;
  //background-attachment: fixed;
  //background-size:cover;
  background: linear-gradient(rgb(255, 255, 255), rgb(254, 202, 202), rgb(254, 249, 195));
  //background: linear-gradient(rgb(199, 210, 254), rgb(254, 202, 202), rgb(254, 249, 195));
  //background: linear-gradient(to right, rgb(253, 164, 175), rgb(244, 63, 94));
  text-align:center;
  background-attachment: fixed;
}
.error {
  color: white;
  margin: 2rem;

  pre {
    white-space: pre-wrap;
    padding: 1rem;
  }
}

.content {
  display: block;
}

.main {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  width: 75%;
  max-width:1280px;
  margin: auto;
  h1,h2 {
    margin-top:0;
  }

}

.header {
  display: block;
  background: url('/static/logo_header_light.png') center no-repeat;
  width:500px;
  max-width:90%;
  height:20vw;
  min-height:110px;
  max-height:220px;
  background-size:contain;
  margin:0 auto;
}

.menu {
  margin: 1rem 0;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 1rem;
}
